import React from "react";
import { Link } from "gatsby";
import netlify from "../../blogposts/images/netlify.png";
import gatsby from "../../blogposts/thumbnails/gatsby.png";
import youtube from "../../blogposts/thumbnails/youtube.png";
import { copyright } from "../../blogposts/data/SiteConfig";

const Footer = () => (
  <footer className="container">
    <div className="footer">
      <div>
        <a
          href="https://ko-fi.com/pratap22"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ko-fi
        </a>
        <a
          href="https://www.patreon.com/pratap22"
          target="_blank"
          rel="noopener noreferrer"
        >
          Patreon
        </a>
        <Link to="/tweets">Tweets</Link>
        <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
          RSS
        </a>
        <a href="/newsletter" target="_blank" rel="noopener noreferrer">
          Newsletter
        </a>
        <a href="/sip-calculator" target="_blank" rel="noopener noreferrer">
          SIP Calculator
        </a>
      </div>
      <div>
        <a href="https://www.netlify.com/" title="Hosted by Netlify">
          <img
            src={netlify}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-img"
            alt="GitHub"
          />
        </a>
        <a href="https://www.gatsbyjs.org/" title="Built with Gatsby">
          <img
            src={gatsby}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-img"
            alt="GitHub"
          />
        </a>
        <a href="https://www.youtube.com/@pratap2210" title="Youtube">
          <img
            src={youtube}
            target="_blank"
            rel="noopener noreferrer"
            className="footer-img"
            alt="Youtube"
          />
        </a>
      </div>
    </div>
    <div className="copy-right">{copyright}</div>
  </footer>
);
export default Footer;
