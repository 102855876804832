const config = {
  siteTitle: "Pratap Sharma",
  siteTitleShort: "Pratap Sharma",
  siteTitleAlt: "Pratap Sharma",
  siteLogo: "/logos/logo-1024.png",
  siteUrl: "https://pratapsharma.io",
  repo: "https://github.com/pratap22/pratapsharma.io",
  pathPrefix: "",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription:
    "Pratap Sharma is a Full Stack Developer specializing in modern JavaScript.",
  siteRss: "/rss.xml",
  facebookPixelId: "253472632370519",
  postDefaultCategoryID: "Tech",
  newsletter: "https://pratap.substack.com",
  newsletterEmbed: "https://pratap.substack.com/embed",
  userName: "Pratap",
  userEmail: "iam@pratapsharma.io",
  userTwitter: "pratap2210",
  menuLinks: [
    {
      name: "About",
      link: "/me/",
    },
    {
      name: "Blogs",
      link: "/blog/",
    },
    {
      name: "Courses",
      link: "/courses/",
    },
  ],
  themeColor: "#3f80ff", // Used for setting manifest and progress theme colors.
  backgroundColor: "#d5e6ed",
  copyright: `Copyright © ${new Date().getFullYear()}. Pratap Sharma`,
};

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
