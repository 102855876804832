import firebase from "gatsby-plugin-firebase";

const incrementViews = async (id, field) => {
  try {
    const ref = firebase
      .database()
      .ref(field)
      .child(id);

    ref.transaction((currentViews) => currentViews + 1);
  } catch (err) {
    throw new Error(err);
  }
};

export default incrementViews;
