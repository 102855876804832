import { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";
import incrementViews from "../lib/increment-views";

const ViewCounter = ({ id, isPodcast }) => {
  const [viewCount, setViewCount] = useState(undefined);

  useEffect(() => {
    const field =
      process.env.NODE_ENV === "development" ? "views-dev" : "views";
    const key = id.includes("/")
      ? id
          .split("")
          .map((s, i, a) => {
            if (s === "/") {
              if (i === 0 || i === a.length - 1) {
                return "";
              } else {
                return "-";
              }
            } else {
              return s;
            }
          })
          .join("")
      : id;

    incrementViews(key, field);

    const onViews = (newViews) => {
      setViewCount(newViews.val() === 1 ? undefined : newViews.val());
    };

    firebase
      .database()
      .ref(field)
      .child(key)
      .on(`value`, onViews);

    return () => {
      if (firebase.database()) {
        firebase
          .database()
          .ref(field)
          .child(key)
          .off(`value`, onViews);
      }
    };
  }, [id]);

  const viewCountOutput = `${viewCount ? `- ${viewCount}` : ""}`

  return viewCountOutput ? `${viewCountOutput} ${isPodcast ? "listens/views" : "views"} /` : "";
};

export default ViewCounter;
